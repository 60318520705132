import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const PrivacyPolicy = ({handleClose, isPage}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        overflowY:'scroll',
        height: (isPage) ? '90vh' : '80vh',
        bgcolor: (isPage) ? 'null' :  '#f6f6f6',
        border: (isPage) ? 'null' : '2px solid #000',
        borderRadius: '8px',
        boxShadow: (isPage) ? 'null' : 24,
        p: 4,
        color: '#333',
        fontFamily: 'Roboto'
      };

    return (
        <Box sx={style}>
            { !isPage ? 
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            : null
            }
            <Typography id="modal-modal-title" variant="h4" component="h4">
            Privacy Policy
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            OtoCo MATIC LLC - Xtent - Series 67 (“Starta Exchange“) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect from users of our website, located at degen.starta.exchange (the “Site“).
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            1. Questions; Contacting Starta Exchange; Reporting Violations.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact us at <a href="mailto:privacy@starta.exchange">privacy@starta.exchange</a>
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            2. A Note About Children.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                We do not intentionally gather Personal Data from visitors in the US who are under the age of 13. If a child under 13 submits Personal Data to Starta Exchange and we learn that the Personal Data is the information of a child under 13, we will attempt to delete the information as soon as possible.
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            3. Types of Data We Collect.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            “Personal Data” means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data. “Anonymous Data” means data that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the identification of individual persons. You are not required to provide any Personal Data to the Site. However, the transactions conducted from your wallets are publicly accessible on blockchain networks accessed through the Site. Starta Exchange collects Personal Data and Anonymous Data as described below.
            </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
                a. Information Collected via Technology.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Cloudflare: We use Cloudflare to help analyze how users use the Site. Cloudflare may use cookies to measure the performance of the Start Exchange Site. You can find out more about these cookies here: <a href="https://developers.cloudflare.com/fundamentals/reference/policies-compliances/cloudflare-cookies/">Cloudflare Cookies.</a>
                    <p/>
                    DeForm: We use DeForm to collect information that you voluntarily provide to us when expressing an interest in obtaining more information about us or other Starta branded products. More information about the possible types of data collected by DeForm can be found here: <a href="https://contributionlabs.notion.site/DeForm-Privacy-Policy-a5cf3d29c5784faaa4383bf0a67d446f#db3f607696b844dfaa3e555ff58f18ef">DeForm Privacy Policy.</a>
                    <p/>
                    ArcX Analytic: We use ArcX Analytics to help analyze how users use the Starta Exchange Site. More information about the data collected by ArcX Analytics can be found here: <a href="https://arcx.notion.site/Privacy-Policy-12e5f98a1756409d9ec72110b430eed5">ArcX Analytics Privacy Policy.</a>
                 </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            4. Use of Your Personal Data
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            a. General Use.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            In general, Personal Data you submit to us is used to respond to requests that you make. We use your Personal Data in the following ways:
                <ol type="i">
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}>to provide improved administration of the Site;</li>
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}>to improve the quality of experience when you interact with the Site</li>
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}>to send you follow-up communications when you contact us via e-mail or submit feedback; and</li>
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}>to send you administrative e-mail notifications, such as updates regarding the Site, security, or support and maintenance advisories.</li>
                </ol>
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            5. Disclosure of Your Personal Data
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.    </Typography>
                <ol type="a">
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}> <b>Third Party Service Providers.</b> We may share your Personal Data with third party service providers to: to conduct quality assurance testing; to provide technical support; and/or to provide other services to Starta Exchange. These third party service providers are required not to use your Personal Data other than to provide the services requested by Starta Exchange.</li>
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}> <b>Starta Brand Companies.</b> We may share some or all of your Personal Data with entities associated with the Starta brand. We may also share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.</li>
                    <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}> <b>Other Disclosures.</b> Regardless of any choices you make regarding your Personal Data, Starta Exchange may disclose Personal Data if it believes in good faith that such disclosure is necessary (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on Starta Exchange; (c) to protect or defend the rights or property of Starta Exchange or users of the Starta Exchange Site; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Use.</li>
                </ol>
            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            6. Third Party Websites
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The Starta Exchange Site may contain links to third party websites. When you click on a link to any other website or location, you will leave our Site and go to another site, and another entity may collect Personal Data or Anonymous Data from you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website you visit. The links to third party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content or websites.
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            7. Your Choices Regarding Information
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have several choices regarding the use of information on the Starta Exchange Site:
            <ol type="a">
                <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}><b>Email Communications.</b> We may periodically send you free newsletters and promotional e-mails if you have indicated that you would like us to do so. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly (please see contact information above). Despite your indicated e-mail preferences, we may send you service related communications, including notices of any updates to our Terms of Use or Privacy Policy.</li>
                <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}><b>Cookies.</b> If you decide at any time that you no longer wish to accept Cookies from the Starta Exchange Site for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser’s technical information. If you do not accept Cookies, however, you may not be able to use all portions, or all functionality, of the Cooley GO Site. If you have any questions about how to disable or modify Cookies, please let us know at the contact information provided above.</li>
                <li style={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif'}}><b>Changing or Deleting Your Personal Data.</b>  Personal Data submitted will be retained for such period as may be required to fulfil the purposes set out in this Privacy Policy, or such other period as may be required by law. You may request deletion of your Personal Data by us, and we will use commercially reasonable efforts to honor your request, but please note that we may be required by law to keep such information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements). When we delete any information, it will be deleted from the active database, but may remain in our archives. If you reside in the EEA, in the event that such Personal Data remains in our archives, Starta Exchange will ensure that this data is put beyond use.</li>
            </ol>
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ mt: 4 }}>
            8. Social Media
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                We use social and developer networks such as Farcaster, Discord, Twitter, Telegram and Github. When you use them, the operators of the respective social and developer networks may record that you are on such networks. This processing of your personal data lays in the responsibility of these networks and occurs according to their privacy policy. Please check with these individual social and developer networks regarding their privacy policies. Starta Exchange is not responsible for data collected by these networks. We only use these platforms to inform our community of updates and answer user questions.  
            </Typography>
            { !isPage ? 
            <Button startIcon={<CloseIcon />}variant="outlined" style={{marginTop: 20, color: '#777', borderColor: '#777'}} onClick={handleClose} >Close</Button>
            : null
            }
        </Box>
    )
}

export default PrivacyPolicy;