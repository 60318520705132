
import Select from 'react-select';
import Chain from '../chain';
import { useEffect, useState } from 'react';
import styles from "../../pages/Home.module.css";

import { abi } from '../../constants/abi';
import { degenBridgeAbi } from '../../constants/degenBridgeAbi';
import { erc20Abi } from '../../constants/erc20';

import { ethers } from 'ethers';
import { Oval } from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import * as animationData from '../../assets/animations/success.json';
import Lottie from 'react-lottie';
import EvStationIcon from '@mui/icons-material/EvStation';

import { useReadContract, useSwitchChain, useWriteContract, useBalance, useWaitForTransactionReceipt, useConfig } from 'wagmi'
import { useAccount } from "wagmi";


import { WalletButton } from '../WalletButton';
import { SwapButton } from '../SwapButton';
import { base } from 'viem/chains';
import { DegenChain } from '../../constants/DegenChain';
import { waitForTransactionReceipt } from '@wagmi/core';
import { degenToBaseAbi } from '../../constants/degenToBaseAbi';
import { useArcxAnalytics } from '@arcxmoney/analytics';
import { SimpleDialog } from '../SelectToken';

const SwapWidget = () => {
  const config = useConfig();
    const {address, chainId, isConnected} = useAccount()
    const {switchChainAsync} = useSwitchChain();
    const { writeContractAsync } = useWriteContract();

    const [fromChain, setFromChain] = useState(chainId == DegenChain.id ? 1 : 0);
    const [toChain, setToChain] = useState(chainId == DegenChain.id ? 0 : 1);

    const [fromToken, setFromToken] = useState('DEGEN');
    const [toToken, setToToken] = useState('DEGEN');

    const [sourceTokenAmount, setSourceTokenAmount] = useState("");
    const [destinationTokenAmount, setDestinationTokenAmount] = useState("");

    const [tokenBalance, setTokenBalance] = useState(null);
    const [fetchingBalance, setFetchingBalance] = useState(false);

    const [loading, setLoading] = useState(false);

    const [bridgeButtonDisabled, setBridgeButtonDisabled] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  
    const chains = [{logo: "/base_logo.webp", name: "Base"}, {logo: "/degentips_32.png", name: "Degen"}];
    const tokens = {'DEGEN': {logo: "/degentips_32.png", name:"DEGEN"}, 'WETH': {logo: "/weth_logo.png", name:"WETH"}, 'ETH': {logo: "/eth_logo.png", name:"ETH"}}
  
    //Backdrop
    const [open, setOpen] = useState(false);
    const [bridgeStep, setBridgeStep] = useState(-1);

    const handleClose = () => {
      setOpen(false);
      if(bridgeStep == 3) {
        window.location.href = "https://starta.deform.cc/lw/"; 
      }
      setBridgeStep(-1);
      setSourceTokenAmount('');
      setDestinationTokenAmount('');
    };

    const handleOpen = () => {
      setOpen(true);
    };

    //Token Selector
    const [ openTokenSelector, setOpenTokenSelector ] = useState(false);
    const [ selectedToken, setSelectedToken ] = useState('DEGEN');
    const [tokenSelectorType, setTokenSelectorType] = useState('source');

    const [tokenSelectorChain, setTokenSelectorChain] = useState('Base')
  
    const handleOpenTokenSelector = (tokenType) => {
      if(tokenType == 'source') {
        setSelectedToken(fromToken);
        if(fromChain==0) {
          setTokenSelectorChain('Base');
        } else {
          setTokenSelectorChain('Degen');
        }
      } else {
        setSelectedToken(toToken);
        if(toChain==1) {
          setTokenSelectorChain('Degen');
        } else {
          setTokenSelectorChain('Base');
        }
      }
      setOpenTokenSelector(true);
      setTokenSelectorType(tokenType);
    };
  
    const handleCloseTokenSelector = (value) => {
      setOpenTokenSelector(false);
      if(tokenSelectorChain == 'Base' && value == 'ETH') {
        if(fromChain == 0) {
          setToToken('WETH')
          setFromToken(value);
        } else {
          setFromToken('WETH');
          setToToken(value)
        }
      } else {
        setFromToken(value)
        setToToken(value)
      }
    };
    
    //arcx analytics 
    const sdk = useArcxAnalytics();
    useEffect(() => {
      if (address && chainId && sdk) {
        // Track the wallet connection with the SDK
        sdk.wallet({
          chainId,
          account: address,
        });
      }
    }, [address, chainId, sdk])

    useEffect(() => {
      //console.log("From Chain: ", fromChain);
      //console.log("Locale: ", navigator.languages);

      let sourceChainId = getChainId(fromChain);
      //console.log("From Chain ID: ", sourceChainId);

      if(chainId != sourceChainId) {
        //console.log(`chainId: ${chainId} != sourceChainId: ${sourceChainId}`);
        setLoading(true);
        switchChainAsync({chainId: sourceChainId}).then(() => {
          setLoading(false);
        }).catch(error => {
            if(error.shortMessage) {
              setErrorMessage(error.shortMessage);
            } else {
              setErrorMessage("Chain Switch Failed");
            }
            setShowError(true);
        })
      }
    }, [ chainId, fromChain ])

    const degenContractOnBase = {
      abi: erc20Abi,
      address: '0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed',
    }

    const wethContractOnBase = {
      abi: erc20Abi,
      address: '0x4200000000000000000000000000000000000006',
    }

    const wethContractOnDegen = {
      abi: erc20Abi,
      address: '0xF058Eb3C946F0eaeCa3e6662300cb01165c64edE',
    }

    const {refetch : fetchNativeTokenBalance } = useBalance({
      address
    });

    const {refetch: fetchBaseDegenBalance } = useReadContract({
      ...degenContractOnBase,
      functionName: 'balanceOf',
      args: [address]
    })
    
    const {refetch: fetchBaseWethBalance } = useReadContract({
      ...wethContractOnBase,
      functionName: 'balanceOf',
      args: [address]
    })

    const {refetch: fetchDegenWethBalance } = useReadContract({
      ...wethContractOnDegen,
      functionName: 'balanceOf',
      args: [address]
    })

    const {refetch: checkDegenAllowance } = useReadContract({
      ...degenContractOnBase,
      functionName: 'allowance',
      args: [address, process.env.REACT_APP_EVM_CONTRACT_ADDRESS]
    });

    const {refetch: checkWethAllowance } = useReadContract({
      ...wethContractOnBase,
      functionName: 'allowance',
      args: [address, process.env.REACT_APP_EVM_CONTRACT_ADDRESS]
    });

    const {refetch: checkWethAllowanceOnDegen } = useReadContract({
      ...wethContractOnDegen,
      functionName: 'allowance',
      args: [address, process.env.REACT_APP_DEGEN_OUT_CONTRACT_ADDRESS]
    });

    useEffect(() => {
      if(fromToken == 'ETH' || fromToken == 'WETH') {
        setDestinationTokenAmount(sourceTokenAmount);
      }
    }, [fromToken])

    useEffect( () => {
      
      setFetchingBalance(true);

      if(chainId == base.id) {
        if(fromToken == 'ETH') {
          fetchNativeTokenBalance().then(({data}) => {
            setTokenBalance(formatTokenBalance(data?.value));
            setFetchingBalance(false);
          })
        } else if(fromToken == 'WETH'){
          //WETH
          fetchBaseWethBalance().then((result) => {
            setTokenBalance(formatTokenBalance(result.data));
            setFetchingBalance(false);
          })
        } else {
          fetchBaseDegenBalance().then((result) => {
            setTokenBalance(formatTokenBalance(result.data));
            setFetchingBalance(false);
          })
        }
      } else if(chainId == DegenChain.id) {
        //WETH
        if(fromToken == 'WETH') {
          fetchDegenWethBalance().then((result) => {
            setTokenBalance(formatTokenBalance(result.data));
            setFetchingBalance(false);
          })
        } else {
          //DEGEN
          fetchNativeTokenBalance().then(({data}) => {
            setTokenBalance(formatTokenBalance(data?.value));
            setFetchingBalance(false);
          })
        }
      }
    }, [chainId, open, address, fromToken])




    const validateDegenAmount = () => {
      let amount = Number(sourceTokenAmount);
      if(amount >= 66 && amount <= 1000000 && amount <= tokenBalance) {
        setShowError(false);
        return true;
      } else{
        if(amount < 66) {
          setErrorMessage("Minimum amount is 66 DEGEN");
        } else if(amount > 1000000) {
          setErrorMessage("Maximum amount is 1000000 DEGEN");
        } else {
          setErrorMessage("Insufficient Balance: Wallet Balance is less than amount entered");
        } 
        setShowError(true);
        setBridgeButtonDisabled(true);
        return false;
      }
    }

    const validateWethAmount = () => {
      let amount = Number(sourceTokenAmount);
      if(amount >= 0.00066 && amount <= 6.66 && amount <= tokenBalance) {
        setShowError(false);
        return true;
      } else{
        if(amount < 0.00066) {
          setErrorMessage("Minimum amount is 0.00066 WETH");
        } else if(amount > 6.66) {
          setErrorMessage("Maximum amount is 6.66 WETH");
        } else {
          setErrorMessage("Insufficient Balance: Wallet Balance is less than amount entered");
        } 
        setShowError(true);
        setBridgeButtonDisabled(true);
        return false;
      }
    }

    const validateEthAmount = () => {
      let amount = Number(sourceTokenAmount);
      if(amount >= 0.00066 && amount <= 6.66 && amount <= tokenBalance) {
        setShowError(false);
        return true;
      } else{
        if(amount < 0.00066) {
          setErrorMessage("Minimum amount is 0.00066 ETH");
        } else if(amount > 6.66) {
          setErrorMessage("Maximum amount is 6.66 ETH");
        } else {
          setErrorMessage("Insufficient Balance: Wallet Balance is less than amount entered");
        } 
        setShowError(true);
        setBridgeButtonDisabled(true);
        return false;
      }
    }

    const onClickWallet = () => {
      document.getElementsByClassName('web3Connect')[0].click();
    }
    

    const onClickBridge = () => {
      let sourceChainId = 0;
      if(fromChain == 0) {
        sourceChainId = base.id;
      } else if(fromChain == 1) {
        sourceChainId = DegenChain.id;
      } 

      if (chainId == sourceChainId) {
        if(sourceChainId == DegenChain.id) {
          bridgeDegenOut();
        } else {
          approveAndBridge();
        }
          
      } else {
          switchChainAsync({chainId: sourceChainId}).then(() => {
            if(sourceChainId == DegenChain.id) {
              bridgeDegenOut();
            } else {
              approveAndBridge();
            }
          }).catch(error => {
            console.error(error);
            if(error.shortMessage) {
              
              setErrorMessage(error.shortMessage);
            } else {
              setErrorMessage("Transaction Failed");
            }
            setErrorMessage("Please switch chain to Base.");
            setShowError(true);
            return;
          })
      }
    }

    const approveAndBridge = async () => {
      if(fromToken == 'DEGEN' && !validateDegenAmount() || fromToken == 'WETH' && !validateWethAmount() || fromToken == 'WETH' && !validateEthAmount()) {
        return;
      } 
      setLoading(true);
      setBridgeStep(0);
      handleOpen();

      if(fromToken == 'ETH') {
        bridgeTokens().then(tx => {
          sdk.transaction({
            transactionHash: tx,
            metadata: {
              action: 'Bridge Base To Degen',
            },
          });
          setBridgeStep(2)
          setLoading(false);
          setTimeout(() => setBridgeStep(3), 10000)
        }).catch(handleTxError)
        return;
      }

      const existingAllowance = (fromToken == 'DEGEN') ? await checkDegenAllowance() : await checkWethAllowance();
      let tokenAmount = ethers.utils.parseUnits(sourceTokenAmount, "ether"); 
      let existingAllowanceAmount = ethers.BigNumber.from(existingAllowance.data);

      if(tokenAmount.lte(existingAllowanceAmount)) {
        bridgeTokens().then(tx => {
          sdk.transaction({
            transactionHash: tx,
            metadata: {
              action: 'Bridge Base To Degen',
            },
          });
          setBridgeStep(2)
          setLoading(false);
          setTimeout(() => setBridgeStep(3), 10000)
        }).catch(handleTxError)
      } else {
        const tx = await approveTransfer()
        const approval = waitForTransactionReceipt(config, {confirmations: 3, hash: tx })
        approval.then(() => {
          bridgeTokens().then(tx => {
            //setTransferApproved(false);
            sdk.transaction({
              transactionHash: tx,
              metadata: {
                action: 'Bridge Base To Degen',
              },
            });
            setBridgeStep(1);
            setBridgeStep(2)
            setLoading(false);
            setTimeout(() => setBridgeStep(3), 10000)
          }).catch(handleTxError)
        }).catch(handleTxError)
      }
    }

    const handleTxError = (error) => {
      console.error("ERROR", error);
      //console.log(error);
     // console.log("ERROR LOG", JSON.stringify(error));
      if(error.shortMessage) {
        let message = error.shortMessage
              if(error.name == 'ContractFunctionExecutionError') {
                message = `Transaction reverted. Please try again in 5 seconds.`
              }
        setErrorMessage(message);
      } else {
        setErrorMessage("Transaction Failed");
      }
      setShowError(true);
      setLoading(false);
      handleClose();
    }

    const bridgeDegenOut = async () => {
      if (fromToken == 'DEGEN' && !validateDegenAmount() || fromToken == 'WETH' && !validateWethAmount()) {
        return;
      } 
      setBridgeStep(1);
      setLoading(true);
      handleOpen();

      if(fromToken == 'WETH') {
        const existingAllowance = await checkWethAllowanceOnDegen();
        let tokenAmount = ethers.utils.parseUnits(sourceTokenAmount, "ether"); 
        let existingAllowanceAmount = ethers.BigNumber.from(existingAllowance.data);
        let bridgeFee = ethers.utils.formatUnits(ethers.utils.parseUnits("20", 'ether'), "wei");
        
        if(tokenAmount.lte(existingAllowanceAmount)) {
         // console.log(`Token Amount: ${tokenAmount}, Allowance: ${existingAllowanceAmount}`);
          tokenAmount = ethers.utils.formatUnits(tokenAmount, "wei")
          bridgeWethOutAuto(tokenAmount, bridgeFee);
        } else {
          const tx = await approveTransferOnDegen();
          const approval = waitForTransactionReceipt(config, {confirmations: 3, hash: tx })
          approval.then(() => {
            tokenAmount = ethers.utils.formatUnits(tokenAmount, "wei");
            bridgeWethOutAuto(tokenAmount, bridgeFee);
          })
        }
      } else {
      let tokenAmount = ethers.utils.formatUnits(ethers.utils.parseUnits(sourceTokenAmount, 'ether'), "wei");
      writeContractAsync({
        //...degenToBaseBridge,
        abi: degenToBaseAbi,
        address: process.env.REACT_APP_DEGEN_OUT_CONTRACT_ADDRESS,
        functionName: 'bridgeDegenOutAuto',
        args: [tokenAmount],
        value: tokenAmount,
        chainId: DegenChain.id
      }).then(tx => {
        sdk.transaction({
            transactionHash: tx,
            metadata: {
              // Example metadata
              action: 'Bridge Degen To Base',
            },
          });
          setBridgeStep(2);
          setLoading(false);
          setTimeout(() => setBridgeStep(3), 10000)
      }).catch(error => {
         // console.log(error);
          if(error.shortMessage) {
            setErrorMessage(error.shortMessage);
          } else {
            setErrorMessage("Transaction Failed");
          }
          setShowError(true);
          setLoading(false);
          handleClose();
      })
    }
    }

    const bridgeWethOutAuto = (tokenAmount, bridgeFee) => {
      writeContractAsync({
        abi: degenToBaseAbi,
        address: process.env.REACT_APP_DEGEN_OUT_CONTRACT_ADDRESS,
        functionName: 'bridgeWETHOutAuto',
        args: [tokenAmount],
        value: bridgeFee,
        chainId: DegenChain.id
      }).then(tx => {
        sdk.transaction({
            transactionHash: tx,
            metadata: {
              action: 'Bridge Degen To Base',
            },
          });
          setBridgeStep(2);
          setLoading(false);
          setTimeout(() => setBridgeStep(3), 10000)
      }).catch(error => {
          //console.log(error);
          if(error.shortMessage) {
            setErrorMessage(error.shortMessage);
          } else {
            setErrorMessage("Transaction Failed");
          }
          setShowError(true);
          setLoading(false);
          handleClose();
      })
    }

    const approveTransfer = () => {
      let tokenAmount = ethers.utils.formatUnits(ethers.utils.parseUnits(sourceTokenAmount, 'ether'), "wei");
     if(fromToken == 'WETH') {
        return writeContractAsync({
          ...wethContractOnBase,
          functionName: 'approve',
          args: [process.env.REACT_APP_EVM_CONTRACT_ADDRESS, tokenAmount]
        })
     }
      return writeContractAsync({
        ...degenContractOnBase,
        functionName: 'approve',
        args: [process.env.REACT_APP_EVM_CONTRACT_ADDRESS, tokenAmount]
      })
    }
    const approveTransferOnDegen = () => {
      let tokenAmount = ethers.utils.formatUnits(ethers.utils.parseUnits(sourceTokenAmount, 'ether'), "wei");
      return writeContractAsync({
        ...wethContractOnDegen,
        functionName: 'approve',
        args: [process.env.REACT_APP_DEGEN_OUT_CONTRACT_ADDRESS, tokenAmount]
      })
    }


    const bridgeTokens = () => {
      setLoading(true);
      let tokenAmount = ethers.utils.formatUnits(ethers.utils.parseUnits(sourceTokenAmount, 'ether'), "wei")
      //console.log("TOken Amount: ", tokenAmount)
      if(fromToken == 'WETH') {
        return writeContractAsync({
          ...bridgeContract,
          functionName: 'bridgeWETH',
          args: [tokenAmount]
        });
      } else if (fromToken == 'ETH') {
        return writeContractAsync({
          ...bridgeContract,
          functionName: 'bridgeETH',
          args: [tokenAmount],
          value: tokenAmount
        });
      } else {
        return writeContractAsync({
          ...bridgeContract,
          functionName: 'bridgeDegen',
          args: [tokenAmount]
        });
      }
      
    }


    const swapChainsAndTokens = () => {
      setLoading(true);
      let tmpChain = fromChain;
      let tmpToken = fromToken;
      setFromChain(toChain);
      setFromToken(toToken);
      setToChain(tmpChain);
      setToToken(tmpToken);
      setSourceTokenAmount('');
      setDestinationTokenAmount('');

      //switchChainAsync({chainId: sourceChainId});
      
    }
    const [selectedOption, setSelectedOption] = useState(null);
  
    // handle onChange event of the dropdown
    const handleChange = (e) => {
      setSelectedOption(e);
    }
    const showList = false;



    const onChangeSourceTokenAmount = event => {
      
      let amountString = event.target.value; //.replaceAll(",", "");
      if(amountString == ".") {
        setSourceTokenAmount(amountString);
        return;
      }
      let amount = Number(amountString);
      if(!Number.isFinite(amount) || amount < 0) {
        return;
      }
      setSourceTokenAmount(amountString);
      if(fromChain == 1 && fromToken == 'DEGEN') {
        if(amount < 20) {
          setDestinationTokenAmount("0");
        } else {
          setDestinationTokenAmount(String((amount-20).toFixed(amountString.split(".")[1]?.length)));
        }
      } else {
        setDestinationTokenAmount(amountString);
      }
      
      if(amount >= 66 && amount > 1000000 && amount <= tokenBalance) {
        setBridgeButtonDisabled(false);
        setShowError(false);
      } 
    };

  

    const onChangeDestinationTokenAmount = event => {
      let amountString = event.target.value //.replaceAll(",", "");
      if(amountString == ".") {
        setDestinationTokenAmount(amountString);
        return;
      }
      let amount = Number(amountString);
      if(!Number.isFinite(amount) || amount < 0) {
        return;
      }
      setDestinationTokenAmount(amountString);
      if(fromChain == 1 && fromToken == 'DEGEN') {
        setSourceTokenAmount(String(amount + 20));
      } else {
        setSourceTokenAmount(amountString);
      }
      if(amount >= 66 && amount > 1000000 && amount <= tokenBalance) {
        setBridgeButtonDisabled(false);
        setShowError(false);
      } 
    };



    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    return (
      <div className={styles.swapwidget}>
                <SimpleDialog
                  selectedValue={selectedToken}
                  open={openTokenSelector}
                  onClose={handleCloseTokenSelector}
                  tokenType={tokenSelectorType}
                  chain={tokenSelectorChain}
                />
              <Backdrop
                sx={{ color: '#fff', backgroundColor:'rgba(5, 35, 62, .6)', backdropFilter:'blur(8px)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
              > {
                bridgeStep === 3 ?
                <div>
                  <span style={{display: 'flex', justifyContent: 'center', fontSize: 40}}>Success!</span>
                  <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                  />
                  <span style={{fontSize: 18}}>Your funds are on their way!</span>
                    <button className={styles.txButton} onClick={(e) => {
                      e.preventDefault();
                      if(fromToken == 'DEGEN') {
                        window.open(`https://explorer.degen.tips/address/${address}`, "_blank");
                      } else {
                        window.open(`https://explorer.degen.tips/address/${address}?tab=token_transfers`, "_blank");
                      }
                      }}>
                          Check Degen Chain
                    </button>
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CircularProgress size={200} />
                  { bridgeStep == 0 ?
                    <span style={{fontSize: 24, display:'block', textAlign: 'center', marginTop: 40}}>Waiting for Approval</span>
                  : bridgeStep == 1 ? 
                    <span style={{fontSize: 24, display:'block', textAlign: 'center', marginTop: 40}}>Waiting for Signature</span>
                  : bridgeStep == 2 ? 
                  <span style={{fontSize: 24, display:'block', textAlign: 'center', marginTop: 40}}>Bridging</span>
                  : null
                  }
                </div>
                
              }
                
              </Backdrop>
        <div className={styles.innercontainer}>
          <div className={styles.inputsection}>
            <div className={styles.fromsection}>
              <div className={styles.frame}>
                <div className={styles.fromChainRoot}>
                  <div className={styles.from}>From</div>
                  
                  <Chain logo={chains[fromChain].logo} name={chains[fromChain].name}/>
                </div>
                {
                <WalletButton/>
                }
              </div>
              <div className={styles.frame1}>
                <div className={styles.frame2}>
                  <div className={styles.send}>Send:</div>
                  <div className={styles.frame3}>
                  
                    { 
                      isConnected ?
                        fetchingBalance ?
                          <Skeleton  variant="text" width={70} sx={{bgcolor: 'rgba(45, 53, 66, 0.65)', fontSize: 14 }} />
                          :
                          <div className={styles.max}>Max: {Number(tokenBalance).toFixed(2)}</div>
                      :
                      null
                    }
                  </div>
                </div>
                <div className={styles.frame4}>
                  <input
                    className={styles.input}
                    placeholder="0.00"
                    type="text"
                    value={ sourceTokenAmount }
                    onChange={onChangeSourceTokenAmount}
                    disabled={loading}
                  />
                    <div>
                        <button className={styles.sourcetoken} onClick={() => handleOpenTokenSelector('source')}>
                            <img
                            className={styles.logoIcon1}
                            alt=""
                            src={tokens[fromToken].logo}
                            />
                            <div className={styles.symbol3}>{tokens[fromToken].name}</div>
                            { <img className={styles.frameIcon} style={{marginLeft: 8}} alt="" src="/chevron-down-solid.svg" /> }
                        </button>
                        {
                            showList ? 
                            <div style={{position: 'absolute', border: '1px solid #dddddd', borderRadius: '8px', padding: 16,}}>
                            <div >
                                <img
                                    className={styles.logoIcon1}
                                    alt=""
                                    src={tokens[fromToken].logo}
                                />
                                <div className={styles.symbol3}>{tokens[fromToken].name}</div>
                                </div>
                            </div>
                            : null

                        }
                        </div>
                  
                </div>
              </div>
            </div>
              <button className={styles.swap} onClick={swapChainsAndTokens} disabled={loading}>
                <img
                  className={styles.arrowSwapIcon}
                  alt=""
                  src="/arrowswap.svg"
                />
              </button>
            <div className={styles.fromsection}>
              <div className={styles.frame}>
                <div className={styles.fromChainRoot}>
                  <div className={styles.from}>To</div>
                  <Chain logo={chains[toChain].logo} name={chains[toChain].name}/>
                </div>
                {
                fromChain == 1 ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <EvStationIcon />
                  <span style={{fontSize: '16px', marginLeft: '8px', color: '#fafafa'}}>20 DEGEN</span>
                </div>
                : null
                }
                
              </div>
              <div className={styles.frame1}>
                <div className={styles.frame7}>
                  <div className={styles.send}>Receive:</div>
                </div>
                <div className={styles.frame9}>
                  <input
                    className={styles.input}
                    placeholder="0.00"
                    type="text"
                    value={destinationTokenAmount}
                    onChange={onChangeDestinationTokenAmount}
                    disabled={loading}
                  />
                  <div className={styles.destinationtoken} onClick={() => handleOpenTokenSelector('destination')}>
                    <img
                      className={styles.logoIcon1}
                      alt=""
                      src={tokens[toToken].logo}
                    />
                    <div className={styles.symbol3}>{tokens[toToken].name}</div>
                    { <img className={styles.frameIcon} style={{marginLeft: 8}} alt="" src="/chevron-down-solid.svg" /> }
                  </div>
                </div>
              </div>
            </div>{
              showError ? 
              <Alert severity="error" style={{width: '-webkit-fill-available', backgroundColor: 'rgb(22, 11, 11)', color: 'rgb(244, 199, 199)', borderRadius: 6,
                margin: '-16px 0 -16px'}}>{errorMessage}</Alert>
              : null
            }
            
          </div>
          <SwapButton onClickBridge={onClickBridge} loading={loading} />

        </div>
      </div>
    );
  }

  const getChainId = (chainNumber) => {
    switch(chainNumber) {
      case 0:
        return base.id;
      case 1:
        return DegenChain.id;
    }
  }

  const bridgeContract = {
    address: process.env.REACT_APP_EVM_CONTRACT_ADDRESS,
    abi: abi
  }



  const degenToBaseBridge = {
    address: process.env.DEGEN_OUT_CONTRACT_ADDRESS,
    abi: degenToBaseAbi
  }

  const formatTokenBalance = (balance) => {
    if(!balance) {
      return null;
    }
    return Number(ethers.utils.formatUnits(ethers.utils.parseUnits(String(balance), 'wei'), "ether"));
  }

  export default SwapWidget;