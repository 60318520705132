import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { BaseChain } from "./constants/BaseChain";
import { ContextProvider } from "./providers/ContextProvider";
import { Web3Provider } from "./providers/Web3Provider";
import { ArcxAnalyticsProvider } from '@arcxmoney/analytics';
import PrivacyPolicy from "./constants/privacyPolicy";
import Terms from "./constants/termsOfService";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <ArcxAnalyticsProvider apiKey={process.env.REACT_APP_ARCX_API_KEY || "" }>
      <Web3Provider>
        <ThemeProvider theme={darkTheme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy handleClose={null} isPage={true} />} />
            <Route path="/terms" element={<Terms handleClose={null} isPage={true} />} />
          </Routes>
        </ThemeProvider>
      </Web3Provider>
    </ArcxAnalyticsProvider>
  );
}
export default App;
