import { FunctionComponent, useEffect, useState } from "react";
import styles from "./Home.module.css";

import SwapWidget from "../components/SwapWidget";

import { useAccount } from "wagmi";

import Modal from '@mui/material/Modal';
import Terms from "../constants/termsOfService";
import { isMobile } from "react-device-detect";
import { base } from "viem/chains";
import { DegenChain } from "../constants/DegenChain";
import PrivacyPolicy from "../constants/privacyPolicy";

const Home: FunctionComponent = () => {
  const {address, chainId, isConnected} = useAccount()


  const [openTerms, setOpenTerms] = useState(false);
  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const handleOpenPrivacyPolicy = () => setOpenPrivacyPolicy(true);
  const handleClosePrivacyPolicy = () => setOpenPrivacyPolicy(false);


  return (
    <div style={{minHeight: '100vh', backgroundColor: '#05233e', display: 'grid'}}>
    <div className={styles.home}>
      <div className={styles.body}>
        <div className={styles.title}>{
            isMobile ?
        <img
              className={styles.startaXdegenLogo}
              alt=""
              src="/rocket_light.png"
          /> 
          :
          null
        }
          <span className={styles.starta}>Starta</span>
          <span className={styles.x}>x</span>
          <span>Degen</span>
        </div>
        <div className={styles.subtitle}>
          <div className={styles.sub1} style={{alignItems:'center'}}>
            <img
              className={styles.degenLogoIcon}
              alt=""
              src="/degen-logo@2x.png"
            />
            <div className={styles.degenChainBridge}>Degen Chain Bridge</div>
          </div>
          { chainId ==  DegenChain.id ?
          <span className={styles.copy} >Get Your DEGEN Automatically in 7 Days</span>
          :
          <span className={styles.copy} >Zero-Fee, Trust Minimized Bridge</span>
        
          }
          { /* <CoinsSupported /> */}
        </div>
      </div>
      <SwapWidget />
      
    </div>
    <div style={{width: '100%', textAlign: 'center', alignSelf: 'flex-end'}}>
      <button onClick={handleOpenTerms} className={styles.terms} >Terms & Conditions</button>
      <button onClick={handleOpenPrivacyPolicy} className={styles.terms} style={{marginLeft: 24}} >Privacy Policy</button>
    </div>
      <Modal
        open={openTerms}
        onClose={handleCloseTerms}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Terms handleClose={handleCloseTerms} isPage={false}/>
        </>
      </Modal>
      <Modal
        open={openPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <PrivacyPolicy handleClose={handleClosePrivacyPolicy} isPage={false}/>
        </>
      </Modal>
    </div>
  );

};




const CoinsSupported = () => {
  return (
    <div className={styles.coinssupported}>
    <div className={styles.coin}>
      <img className={styles.icon} alt="" src="/icon.svg" />
      <div className={styles.symbol}>ETH</div>
    </div>
    <div className={styles.coin}>
      <img className={styles.icon} alt="" src="/protocol-icon.svg" />
      <div className={styles.symbol1}>AVAX</div>
    </div>
    <div className={styles.coin}>
      <img className={styles.icon} alt="" src="/protocol-icon.svg" />
      <div className={styles.symbol1}>BTC</div>
    </div>
  </div>
  );
}
export default Home;
