import { Avatar, Dialog, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import styles from "../../pages/Home.module.css";
import CloseIcon from '@mui/icons-material/Close';

const images = {'DEGEN': '/degentips_32.png', 'WETH': '/weth_logo.png', 'ETH': '/eth_logo.png'}; 

export const SimpleDialog = (props) => {
    const { onClose, selectedValue, open, tokenType, chain } = props;
  
    const tokens = ['DEGEN',  'WETH']; 
    if(chain == 'Base') {
      tokens.push('ETH');
    }

    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} open={open} >
        <DialogTitle sx={{color:'#eee', fontFamily:"IBM Plex Mono"}} >Select Token</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 12.7,
            top: 12.7,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <List sx={{ pt: 0, width: 240}} >
          {tokens.map((token) => (
            <ListItem  key={token}>
              <ListItemButton onClick={() => handleListItemClick(token)}>
                { 
                <ListItemAvatar sx={{minWidth: 40}}>
                  <img
                    className={styles.logoIcon1}
                    alt=""
                    src={images[token]}
                    style={{marginTop: 2}}
                    />
                </ListItemAvatar>
                }
                <ListItemText primary={token} sx={{color:'#eee', fontFamily:"IBM Plex Mono"}} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

      </Dialog>
    );
  }