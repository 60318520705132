import { ConnectKitButton } from "connectkit";
import styles from '../../pages/Home.module.css';
import { Oval } from "react-loader-spinner";

export const SwapButton = ({onClickBridge, loading }) => {
    return (
      <ConnectKitButton.Custom>
        {({ isConnected, isConnecting, show, }) => {
          if(loading || isConnecting) {
            return (
              <button className={styles.button} disabled={true}>
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="#fafafa"
                  secondaryColor="#ddd"
                  ariaLabel="oval-loading"
                  wrapperStyle={{opacity: .8}}
                  wrapperClass=""
                  strokeWidth="5"
                  />
              </button>
            )
            
          } else if(isConnected) {
              return (
                <button className={styles.button} onClick={onClickBridge}>
                <div className={styles.destinationtoken}>
                  <div className={styles.connectWallet}>Bridge</div>
                </div>
              </button>
              )
          }

          return (
          <button className={styles.button} onClick={show}>
            <div className={styles.destinationtoken}>
              <div className={styles.connectWallet}>Connect Wallet</div>
            </div>
          </button>
          )
        }}
      </ConnectKitButton.Custom>
    );
  };
