export const DegenChain =  {
    id: 666666666,
    name: 'Degen',
    nativeCurrency: { name: 'Degen', symbol: 'DEGEN', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://rpc.degen.tips'] },
    },
    blockExplorers: {
      default: { name: 'basescout', url: 'https://base.blockscout.com' },
    },
    contracts: {
    },
  };

