import { WagmiProvider, createConfig, http } from "wagmi";
import { base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { DegenChain } from "../constants/DegenChain";

const config = createConfig(
  getDefaultConfig({
    // Your dApps chains
    chains: [base, DegenChain],
    transports: {
      // RPC URL for each chain
      [DegenChain.id]: http(
        `https://rpc.degen.tips`,
      ),
      [base.id]: http(
        `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`,
      ),
    },

    // Required API Keys
    walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,

    // Required App Info
    appName: "Starta X Degen Bridge",

    appUrl: "https://degen.starta.exchange", // your app's url
    appIcon: "https://assets-global.website-files.com/641f5f46b877691da3754c91/641f618e5d2729b5ce53a0d8_boosters-2029393_960_720.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  }),
);

const queryClient = new QueryClient();

export const Web3Provider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider mode="dark">{children}</ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};