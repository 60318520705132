import { ConnectKitButton } from "connectkit";
import styles from '../../pages/Home.module.css';

export const WalletButton = () => {
    return (
      <ConnectKitButton.Custom>
        {({ isConnected, address, show }) => {
          if(isConnected) {
              return (
                <button className={styles.disconnectwallet} onClick={show}>
                  <img className={styles.icon1} alt="" src="/frame.svg" />
                  <div style={{display: 'flex'}}>
                  <div className={styles.label} style={{textOverflow: 'ellipsis', overflow:'hidden', width: 50}}>{address}</div>
                  <div className={styles.label} style={{width: 'fit-content'}}> {address.substring(address.length - 3)}</div>
                  </div>
                </button>
              )
          } else{
            return <></>;
          }

        }}
      </ConnectKitButton.Custom>
    );
  };
