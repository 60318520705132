import styles from "../../pages/Home.module.css";

const Chain = ({logo, name}) => {
    return (
        <div className={styles.chain}>
        <img
          className={styles.logoIcon}
          alt=""
          src={logo}
        />
        <div className={styles.title1}>{name}</div>
      </div>
    )
}

export default Chain;
